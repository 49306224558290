import { initializeApp } from "firebase/app";
import {getFirestore, collection} from 'firebase/firestore'
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB83Tt-NTl50ia1ShOQOGtvY-9ncf9o3Tw",
  authDomain: "reactecom-694f3.firebaseapp.com",
  databaseURL: "https://reactecom-694f3-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "reactecom-694f3",
  storageBucket: "reactecom-694f3.appspot.com",
  messagingSenderId: "913549680653",
  appId: "1:913549680653:web:926efd2ab1b27a3eae7544"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const moviesRef = collection(db, "movies");
export const reviewsRef = collection(db, "reviews");
export const usersRef = collection(db, "users");
export const storage = getStorage(app);

export default app;





